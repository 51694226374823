<template>
  <ion-page>
    <ion-content fullscreen>
      <template v-if="step == 1">
        <h3>{{$t('noteSurveyDescriptionText1')}}</h3>
        <ion-card>
          <ion-card-header>
            <ion-card-title><!--{{ noteCategoryBrand.cate_name }}-->{{$t('noteSurveyDescriptionText2')}}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list lines="none">
              <ion-radio-group :value="answer1" @ionChange="handleBrandAnswer">
                <ion-item v-for="(item, index) in noteCategoryBrandList" :key="index">
                  <ion-label>{{ item.cate_name }}</ion-label>
                  <ion-radio slot="start" :value="index"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header>
            <ion-card-title><!--{{ noteCategoryDeduct1.cate_name }}-->{{$t('noteSurveyDescriptionText3')}}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list lines="none">
              <ion-radio-group :value="answer2" @ionChange="handleAdaptorAnswer">
                <ion-item v-for="(item, index) in noteCategoryDeduct1List" :key="index">
                  <ion-label>{{ item.cate_name }}</ion-label>
                  <ion-radio slot="start" :value="index"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header>
            <ion-card-title><!--{{ noteCategoryDeduct2.cate_name }}-->{{$t('noteSurveyDescriptionText4')}}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list lines="none">
              <ion-radio-group :value="answer3" @ionChange="handleBatteryAnswer">
                <ion-item v-for="(item, index) in noteCategoryDeduct2List" :key="index">
                  <ion-label>{{ item.cate_name }}</ion-label>
                  <ion-radio slot="start" :value="index"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header>
            <ion-card-title><!--{{ noteCategoryDeduct3.cate_name }}-->{{$t('noteSurveyDescriptionText5')}}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list lines="none">
              <ion-radio-group :value="answer4" @ionChange="handleKeyboardAnswer">
                <ion-item v-for="(item, index) in noteCategoryDeduct3List" :key="index">
                  <ion-label>{{ item.cate_name }}</ion-label>
                  <ion-radio slot="start" :value="index"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </template>

      <template v-if="step == 2">
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{ $t('labelLcdQuestionTitle') }}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list lines="none">
              <ion-radio-group :value="lcdCheck" @ionChange="handleLcdCheck">
                <ion-item v-for="(item, index) in noteCategoryDeduct4List" :key="index">
                    <ion-label>{{ item.cate_name }} </ion-label>
                    <ion-radio slot="start" :value="index"></ion-radio>
                  </ion-item>
              </ion-radio-group>
            </ion-list>

            <ion-list class="Survey" lines="none">
              <ion-grid>
                <ion-row>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/notebook/lcd/lcd5.jpg" alt="Example 1" class=""></ion-img>
                    {{$t('labelAnswerNormalExample')}} 1
                    </ion-label>    
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/notebook/lcd/lcd2.jpg" alt="Example 2"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 2
                    </ion-label>
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/notebook/lcd/lcd3.jpg" alt="Example 3"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 3
                    </ion-label>    
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/notebook/lcd/lcd4.jpg" alt="Example 4"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 4
                    </ion-label>    
                  </ion-col>
                </ion-row>

              </ion-grid>
            </ion-list>

          </ion-card-content>
        </ion-card>
      </template>

      <template v-if="step == 3">
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{$t('labelExteriorQuestionTitle')}}</ion-card-title>
          </ion-card-header>
          <ion-card-content>

            <ion-list lines="none">
              <ion-radio-group :value="surfaceCheck" @ionChange="handleSurfaceCheck">
                  <ion-item v-for="(item, index) in noteCategoryDeduct5List" :key="index">
                    <ion-label>{{ item.cate_name }} </ion-label>
                    <ion-radio slot="start" :value="index"></ion-radio>
                  </ion-item>
                </ion-radio-group>
            </ion-list>

            <ion-list class="Survey" lines="none">
              <ion-grid>
                <ion-row>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/notebook/surface/surface5.jpg" alt="Example 1"></ion-img>
                    {{$t('labelAnswerNormalExample')}} 1
                    </ion-label>    
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/notebook/surface/surface2.jpg" alt="Example 2"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 2
                    </ion-label>
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/notebook/surface/surface3.jpg" alt="Example 3"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 3
                    </ion-label>    
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/notebook/surface/surface4.jpg" alt="Example 4"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 4
                    </ion-label>    
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-list>

          </ion-card-content>
        </ion-card>
      </template>

      <ion-button color="primary" expand="full" @click="handleNext">{{$t('buttonLabelNext')}}</ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import Cookies from 'js-cookie'
import { 
  IonPage,
  IonContent, 
  IonButton, 
  IonCard, 
  IonCardHeader, 
  IonCardTitle, 
  IonCardContent,
  IonList,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  toastController,
  useIonRouter
} from '@ionic/vue'
import { watch, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { getData, listData, updateData, addData } from '@/api/board/common'

export default {
  name: 'survey',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonCard, 
    IonCardHeader, 
    IonCardTitle, 
    IonCardContent,
    IonList,
    IonRadioGroup,
    IonItem,
    IonLabel,
    IonRadio
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const route = useRoute()
    const router = useIonRouter()

    let step = ref(1)

    let noteCategoryBrand = ref({})
    let noteCategoryBrandList = ref([])
   
    let noteCategoryDeduct1 = ref({})
    let noteCategoryDeduct1List = ref([])
    
    let noteCategoryDeduct2 = ref({})
    let noteCategoryDeduct2List = ref([])
    
    let noteCategoryDeduct3 = ref({})
    let noteCategoryDeduct3List = ref([])

    let noteCategoryDeduct4 = ref({})
    let noteCategoryDeduct4List = ref([])
        
    let noteCategoryDeduct5 = ref({})
    let noteCategoryDeduct5List = ref([])
    
    let allCategory = ref([])
    let totalPrice = ref(0)
    let payload = ref({
      num: '',
      // total_price: 0,
      basic: '',
      basic_num: '',
      deduct_1: '',
      deduct_2: '',
      deduct_3: '',
      deduct_4: '',
      deduct_5: '',
      deduct_1_num: '',
      deduct_2_num: '',
      deduct_3_num: '',
      deduct_4_num: '',
      deduct_5_num: ''
    })

    let com_main_num = ref(0)
    let answer1 = ref(undefined)
    let answer2 = ref(undefined)
    let answer3 = ref(undefined)
    let answer4 = ref(undefined)
    let lcdCheck = ref(undefined)
    let surfaceCheck = ref(undefined)

    watch(
      () => route.params,
      async () => {
        if (route.path.indexOf('/step/survey') > -1) {
          step.value = 1
          answer1.value = undefined
          answer2.value = undefined
          answer3.value = undefined
          answer4.value = undefined
          lcdCheck.value = undefined
          surfaceCheck.value = undefined
        }
      }, {
        immediate: true
      }
    )

    watch(
      () => answer1.value, newVal => {
        checkCategory(newVal, 'brand')
      }
    )
    
    watch(
      () => answer2.value, newVal => {
        checkCategory(newVal, 'adaptor')
      }
    )
    
    watch(
      () => answer3.value, newVal => {
        checkCategory(newVal, 'battery')
      }
    )
    
    watch(
      () => answer4.value, newVal => {
        checkCategory(newVal, 'keyboard')
      }
    )

    watch(
      () => lcdCheck.value, newVal => {
        checkCategory(newVal, 'lcd')
      }
    )

    watch(
      () => surfaceCheck.value, newVal => {
        checkCategory(newVal, 'surface')
      }
    )

    onMounted(async () => {
      await getAllCategory()
      await getList()
      await getComMainNum()
      await getOrderTotalPrice()
    })
    
    const getList = async () => {
      // Brand
      getCategory(1, noteCategoryBrand)
      getCategories(1, noteCategoryBrandList)

      // deduct1 (Adaptor)
      getCategory(12, noteCategoryDeduct1)
      getCategories(12, noteCategoryDeduct1List)
      
      // deduct2 (Battery)
      getCategory(13, noteCategoryDeduct2)
      getCategories(13, noteCategoryDeduct2List)
      
      // deduct3 (Keyboard)
      getCategory(14, noteCategoryDeduct3)
      getCategories(14, noteCategoryDeduct3List)

      // deduct4 (LCD)
      getCategory(15, noteCategoryDeduct4)
      getCategories(15, noteCategoryDeduct4List)
      
      // deduct5 (surface)
      getCategory(16, noteCategoryDeduct5)
      getCategories(16, noteCategoryDeduct5List)
    }

    const getCategory = async (num, target) => {
      const apiUrl = '/api/notebook/category'
      await getData(apiUrl, num).then(response => target.value = response)
    }
    
    const getCategories = async (num, target) => {
      const apiUrl = `/api/notebook/categories/${num}`
      await listData(apiUrl).then(response => target.value = response)
    }

    const getAllCategory = async () => {
      const apiUrl = '/api/notebook/allcategory'
      allCategory.value = await listData(apiUrl)
    }

    const getOrderTotalPrice = async () => {
      const apiUrl = '/api/notebook/order/totalprice/'
      totalPrice.value = await getData(apiUrl, route.params.num)
    }

    const updateNoteOrder = async () => {
      const apiUrl = `/api/notebook/order/`
      payload.value.num = Number(route.params.num)
      await updateData(apiUrl, payload.value).then(()=> {
        //TMS 발송
        addData('/api/notebook/sendtms', payload.value)
      })
    }

    const handleBrandAnswer = (val) => {
      answer1.value = val.detail.value
    }
    
    const handleAdaptorAnswer = (val) => {
      answer2.value = val.detail.value
    }
    
    const handleBatteryAnswer = (val) => {
      answer3.value = val.detail.value
    }
    
    const handleKeyboardAnswer = (val) => {
      answer4.value = val.detail.value
    }
        
    const handleLcdCheck = (e) => {  
      lcdCheck.value = e.detail.value    
    }

    const handleSurfaceCheck = (e) => {  
      surfaceCheck.value = e.detail.value    
    }

    const getComMainNum = async () => {
      const memId = Cookies.get('memId')
      let apiUrl = `/api/notebook/order/mainnum/${memId}`
      com_main_num.value = await listData(apiUrl)
    }

    const getSpecialInfo = async () => {
      const comNum = com_main_num.value
      const apiUrl = `/api/notebook/specialinfo/${comNum}`
      await listData(apiUrl).then(response => {
        const selectedCateName = noteCategoryBrandList.value[answer1.value].cate_name
        const specialMappingDatas = response.filter(e => e.cate_name === selectedCateName)
        if (specialMappingDatas.length > 0) {
          router.push({
            name: 'special-mapping',
            params: {
              num: route.params.num
            }
          })
        } else {
          router.push({
            name: 'photos',
            params: {
              num: route.params.num
            }
          })
        }
      })
    }
    
    const checkValidate = () => {
      let message
      if (answer1.value === undefined) {
        message = t('messageSurveyText1')
        showMessage(message)
        return false
      } else if (answer2.value === undefined) {
        message = t('messageSurveyText2')
        showMessage(message)
        return false
      } else if (answer3.value === undefined) {
        message = t('messageSurveyText3')
        showMessage(message)
        return false
      } else if (answer4.value === undefined) {
        message = t('messageSurveyText4')
        showMessage(message)
        return false
      } else {
        return true
      }
    }

    const handleNext = async () => {

      let result = true

      if (step.value == 1) {
        if (!checkValidate()){
          result = false
          alert(t('orderRequireInfo'))
        }
      } else if (step.value == 2) {
        if (lcdCheck.value == undefined || lcdCheck.value === '') {
          result = false
          alert(t('orderRequireInfo'))
        }
      } else if (step.value == 3) {
        if (surfaceCheck.value == undefined || surfaceCheck.value === '') {
          result = false
          alert(t('orderRequireInfo'))
        }
      }

      if (!result) {
        return false
      } else {
        if (step.value == 3) {
          if (checkValidate()) {
            await checkCategory()
            await updateNoteOrder()
            await getSpecialInfo()
          } 
        } else if (result && step.value < 4) {
          step.value++
        }
      }
      

      // if (checkValidate()) {
      //   await checkCategory()
      //   await updateNoteOrder()
      //   await getSpecialInfo()
      //   /* router.push({
      //     name: 'photos',
      //     params: {
      //       num: route.params.num
      //     }
      //   }) */
      // }
    }

    const checkCategory = (idx, target) => {
      // check Category
      if (target === 'brand') {
        const brandName = noteCategoryBrandList.value[idx].cate_name      
        allCategory.value.forEach(o => {
          if (o.cate_name === brandName) {
            payload.value.basic = Number(o.price)
            payload.value.basic_num = o.cate_num
          } 
        })
      } else if (target === 'adaptor') {
        payload.value.deduct_1 = Number(noteCategoryDeduct1List.value[answer2.value].price)
        payload.value.deduct_1_num = noteCategoryDeduct1List.value[answer2.value].cate_num
      } else if (target === 'battery') {
        payload.value.deduct_2 = Number(noteCategoryDeduct2List.value[answer3.value].price)
        payload.value.deduct_2_num = noteCategoryDeduct2List.value[answer3.value].cate_num
      } else if (target === 'keyboard') {
        payload.value.deduct_3 = Number(noteCategoryDeduct3List.value[answer4.value].price)
        payload.value.deduct_3_num = noteCategoryDeduct3List.value[answer4.value].cate_num
      } else if (target === 'lcd') {
        payload.value.deduct_4 = Number(noteCategoryDeduct4List.value[lcdCheck.value].price)
        payload.value.deduct_4_num = noteCategoryDeduct4List.value[lcdCheck.value].cate_num
      } else if (target === 'surface') {
        payload.value.deduct_5 = Number(noteCategoryDeduct5List.value[surfaceCheck.value].price)
        payload.value.deduct_5_num = noteCategoryDeduct5List.value[surfaceCheck.value].cate_num
      }

      // setTotalPrice()
    }

    // const setTotalPrice = () => {
    //   Object.keys(payload.value).map(o => {
    //     if (o === 'total_price') {
    //       payload.value.total_price = Number(payload.value.basic) + Number(payload.value.deduct_1) + Number(payload.value.deduct_2) + Number(payload.value.deduct_3) + Number(payload.value.deduct_4) + Number(payload.value.deduct_5)
    //     }
    //   })
    // }

    const showMessage = (message) => {
      toastController.create({
        message: message,
        duration: 3000,
        color: 'danger',
        cssClass: 'custom-toast',
        buttons: [
          {
            text: t('buttonLabelClose'),
            role: 'cancel'
          }
        ],
      }).then(toast => toast.present())
    }

    return {
      noteCategoryBrand,
      noteCategoryBrandList,
      noteCategoryDeduct1,
      noteCategoryDeduct1List,
      noteCategoryDeduct2,
      noteCategoryDeduct2List,
      noteCategoryDeduct3,
      noteCategoryDeduct3List,
      noteCategoryDeduct4,
      noteCategoryDeduct4List,
      noteCategoryDeduct5,
      noteCategoryDeduct5List,
      handleBrandAnswer,
      handleAdaptorAnswer,
      handleBatteryAnswer,
      handleKeyboardAnswer,
      getComMainNum,
      getSpecialInfo,
      handleNext,
      handleLcdCheck,
      handleSurfaceCheck,
      step,
      payload,
      answer1,
      answer2,
      answer3,
      answer4,
      lcdCheck,
      surfaceCheck
    }
  }
}
</script>

<style>
h3 {
  margin-bottom: 20px;
  font-size: 16px;
}

ion-card {
  margin-inline: 0;
}

ion-card-title {
  font-size: 16px;
}

ion-radio {
  margin-inline: 0;
  margin-inline-end: 15px;
}

ion-item {
  --padding-start: 0;
}

ion-tab-bar > ion-tab-button > ion-label {
  font-size:  14px;
}
</style>